.wrapper-icon-form i.lock:before {
  color: gray !important;
  font-size: 70px !important; }

.wrapper-icon-form i.done:before {
  color: #38a435 !important;
  font-size: 70px !important; }

.wrapper-icon-form i.error:before {
  color: #bd2018 !important;
  font-size: 70px !important; }

.wrapper-icon-form i.send {
  background: #38a435 !important;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  position: relative; }
  .wrapper-icon-form i.send:before {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: white !important;
    font-size: 40px !important; }

.invalid-feedback p {
  color: white;
  font-size: 14px;
  margin-bottom: 0px; }

form .form-control {
  border-radius: 50px !important;
  height: 42px;
  border-width: 2px; }

.view-auth form .form-control {
  color: white !important;
  background: transparent; }
  .view-auth form .form-control::placeholder {
    color: white; }
  .view-auth form .form-control:-ms-input-placeholder {
    color: white; }
  .view-auth form .form-control::-webkit-input-placeholder {
    color: white; }

.view-auth .alert {
  margin-top: 10px;
  color: #bd2018;
  text-align: center;
  background: white;
  border: 0px solid; }

.table-striped td, .table-striped th {
  font-size: 14px; }

.accordion .card-header {
  padding: 0px; }
  .accordion .card-header h2 p {
    color: #231f20;
    font-weight: 700;
    text-transform: uppercase; }

.accordion .card-body p {
  font-size: 14px; }

.view-auth {
  background: #2E6839;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .view-auth .container {
    height: 100vh; }
    @media screen and (max-width: 500px), screen and (max-height: 700px) {
      .view-auth .container {
        height: 100%;
        padding-top: 200px;
        padding-bottom: 300px; } }
  .view-auth .footer-auth {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%); }
    .view-auth .footer-auth .link a {
      color: white;
      font-size: 12px; }
  .view-auth .logo-auth {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%); }
